<template>
    <div>
        <div class="tw-grid tw-grid-cols-12 tw-gap-2 tw-mb-12">
            <div
                class="lg:tw-col-span-7 md:tw-col-span-6 sm:tw-col-span-12 tw-col-span-12"
            >
                <div>
                    <div>
                        <ul class="article-details-tab-header tw-mb-4">
                            <li
                                v-on:click="toggleTab()"
                                :class="firstTabActive ? 'isActive' : ''"
                            >
                                Produktmerkmale
                            </li>
                            <li
                                v-on:click="toggleTab()"
                                :class="firstTabActive ? '' : 'isActive'"
                            >
                                Detailinformationen
                            </li>
                        </ul>
                    </div>
                    <div v-if="firstTabActive">
                        <p class="tw-font-light tw-mb-4">
                            Es gibt im Moment in diese Mannschaft, oh, einige
                            Spieler vergessen ihnen Profi was sie sind. Ich lese
                            nicht sehr viele Zeitungen, aber ich habe gehört
                            viele Situationen. Erstens: wir haben nicht offensiv
                            gespielt. Es gibt keine deutsche Mannschaft spielt
                            offensiv und die Name offensiv wie Bayern.
                        </p>
                        <ul class="article-details-listing tw-font-light">
                            <li>
                                <img
                                    src="@/assets/images/Icons/icon-customer.svg"
                                />
                                Erweiterbares Gepäck:
                                <strong class="tw-font-normal">74x53x31</strong>
                                / Volumen
                                <strong class="tw-font-normal">
                                    37 Liter</strong
                                >
                            </li>
                            <li>
                                <img
                                    src="@/assets/images/Icons/icon-instagram.svg"
                                />
                                Besonders leicht:
                                <strong class="tw-font-normal">2,1 Kg</strong>
                            </li>
                            <li>
                                <img
                                    src="@/assets/images/Icons/icon-customer.svg"
                                />
                                Erweiterbares Gepäck:
                                <strong class="tw-font-normal">74x53x31</strong>
                                / Volumen 37 Liter
                            </li>
                            <li>
                                <img
                                    src="@/assets/images/Icons/icon-instagram.svg"
                                />
                                Besonders leicht:
                                <strong class="tw-font-normal">2,1 Kg</strong>
                            </li>
                            <li>
                                <img
                                    src="@/assets/images/Icons/icon-customer.svg"
                                />
                                Erweiterbares Gepäck:
                                <strong class="tw-font-normal">74x53x31</strong>
                                / Volumen 37 Liter
                            </li>
                            <li>
                                <img
                                    src="@/assets/images/Icons/icon-instagram.svg"
                                />
                                Besonders leicht:
                                <strong class="tw-font-normal">2,1 Kg</strong>
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <div class="tw-grid tw-grid-cols-2 tw-font-light">
                            <div>
                                <ul class="article-details-keyval">
                                    <li>
                                        <span class="tw-font-normal"
                                            >Außenmaß (HxBxT):</span
                                        >
                                        55x39x20 cm
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >Gewicht:</span
                                        >
                                        2,1 kg
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >Volumen:</span
                                        >
                                        37 Liter
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >Material:</span
                                        >
                                        Polydingsbums
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >Handgepäck:</span
                                        >
                                        ja
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >Serie:</span
                                        >
                                        Looping
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >Rollensystem:</span
                                        >
                                        4 Rollen
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >Herstellergarantie:</span
                                        >
                                        3 Jahre
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >Besonderheiten:</span
                                        >
                                        3 Jahre
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >Hart-/Weichgepäck:</span
                                        >
                                        Hartgepäck
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >Schlossart:</span
                                        >
                                        TSA-Schloss
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <ul class="article-details-keyval">
                                    <li>
                                        <span class="tw-font-normal">EAN:</span>
                                        4032323444323
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >ArtNr.:</span
                                        >
                                        10344323
                                    </li>
                                    <li>
                                        <span class="tw-font-normal"
                                            >ArtNr. Hersteller (MPN):</span
                                        >
                                        932423-33
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="article-detail-right lg:tw-col-span-5  md:tw-col-span-6 sm:tw-col-span-12 tw-col-span-12"
            >
                <div class="tw-grid tw-grid-cols-8 tw-gap-2 article-details">
                    <div class="sm:tw-col-span-3 article-details-brand">
                        <img
                            src="@/assets/images/singleArticle/Markenlogo-Titan.jpg"
                        />
                    </div>
                    <div
                        class="tw-col-span-5 tw-pl-2 tw-pt-7 article-details-more"
                    >
                        <a class="article-details-link"
                            >Mehr von <span>TITAN</span></a
                        >
                        <img
                            class="tw-inline-block end"
                            src="@/assets/images/Icons/icon_linkpfeil-blau_rechts.svg"
                        />
                        <br />
                        <a class="article-details-link"
                            >Mehr aus der <span>Serie XENON</span></a
                        >
                        <img
                            class="tw-inline-block end"
                            src="@/assets/images/Icons/icon_linkpfeil-blau_rechts.svg"
                        />
                    </div>
                </div>
                <v-divider class="tw-my-5" />
                <h3 class="tw-text-2xl tw-mb-5 sm:tw-text-left tw-text-center">
                    Wir helfen gerne!
                </h3>
                <p class="tw-font-light tw-mb-8 sm:tw-text-left tw-text-center">
                    Ob Beratung, Ersatzteile oder Sonderwünsche - all ihre
                    Fragen und Angliegen behandelt unser Kundenzentrum
                    persönlich und fundiert
                </p>
                <ul class="article-details-listing second-listing">
                    <li class="sm:tw-flex">
                        <img src="@/assets/images/Icons/icon_mail.svg" />
                        <div>
                            <a>E-Mail senden</a>
                            <img
                                class="end"
                                src="@/assets/images/Icons/icon_linkpfeil-blau_rechts.svg"
                            />
                        </div>
                    </li>
                    <li>
                        <img src="@/assets/images/Icons/icon_whatsapp.svg" />
                        <div
                            class="sm:tw-inline-block tw-flex tw-flex-col tw-items-center"
                        >
                            <a>Whatsapp Gespräch beginnen</a>
                            <img
                                class=""
                                src="@/assets/images/Icons/icon_linkpfeil-blau_rechts.svg"
                            />
                        </div>
                    </li>
                    <li>
                        <img
                            class="icon-bigger"
                            src="@/assets/images/Icons/icon-telefon.svg"
                        />
                        <div
                            class="sm:tw-inline-block tw-flex tw-flex-col tw-items-center"
                        >
                            <div class="tw-font-normal">
                                Telefonische Fachberatung
                            </div>
                            <div class="tw-font-light">
                                <span class="tw-text-primary tw-font-medium"
                                    >+496107 / 966 68 66.</span
                                >
                                Mo.-Fr. 9 - 18 Uhr
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <Seperator />
    </div>
</template>

<script>
import Seperator from '@/components/content/items/Seperator';

export default {
    name: 'ArticleDetails',
    components: { Seperator },
    data: () => ({
        firstTabActive: true,
    }),
    methods: {
        toggleTab() {
            // `this` will refer to the component instance
            this.firstTabActive = !this.firstTabActive;
        },
    },
};
</script>

<style scoped>
.article-details-tab-header {
    padding-left: 0;
    cursor: pointer;
}

.article-details-tab-header li {
    display: inline-block;
    margin-right: 24px;
    color: #8a8a8a;
}

.article-details-tab-header li.isActive {
    color: #27348b;
    border-bottom: 4px solid #27348b;
}

@media (max-width: 959px) {
    .article-detail-right {
        border: 0 !important;
        padding-left: 0 !important;
    }
    ul {
        padding-left: 0;
    }
}

.article-detail-right {
    border-left: 1px solid #8a8a8a;
    padding-left: 24px;
}

.article-details-link {
    color: #27348b;
}

.article-details-link span {
    text-decoration: underline;
}

.article-details-listing {
    padding: 0;
}

.article-details-listing li {
    margin-bottom: 16px;
}

.article-details-listing li a {
    color: #27348b;
}

.article-details-more img,
.article-details-listing li img {
    width: 20px;
    margin-top: -2px;
    margin-right: 8px;
    display: inline-block;
}

.article-details-more img.end,
.article-details-listing li img.end {
    margin-left: 4px;
}

.article-details-listing li img.icon-bigger {
    margin-top: -30px;
}

.article-details-keyval li {
    margin-bottom: 8px;
}

@media (max-width: 639px) {
    .article-details {
        display: flex;
        flex-direction: column;
    }
    .article-details .article-details-brand img {
        width: 150px;
        margin: 0 auto;
    }
    .article-details .article-details-more {
        text-align: center;
        padding: 0;
    }
    .article-details-listing.second-listing li {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .article-details-listing.second-listing li > img {
        width: 40px;
        margin: 5% 0;
    }
}
</style>
