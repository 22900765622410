<template>
    <div class="tw-mb-8 tw-items-center">
        <h3 class="tw-text-2xl xs:tw-text-center">{{ label }}</h3>

        <div
            class="xs:tw-flex xs:tw-flex-col xs:tw-items-center sm:tw-grid sm:tw-grid-cols-2 tw-mb-8"
        >
            <div class="tw-font-light xs:tw-text-xl">
                {{ subLabel }}
            </div>
            <div class="article-banner-more xs:tw-hidden tw-block">
                <a class="article-banner-more-link"
                    ><span v-html="moreText"> </span>
                    <img
                        class="article-banner-more-link-icon"
                        src="@/assets/images/Icons/icon_linkpfeil-blau_rechts.svg"
                /></a>
            </div>
        </div>
        <ArticleSlide :articles="articles" />
        <div class="article-banner-more xs:tw-flex tw-hidden">
            <a class="article-banner-more-link tw-text-xl"
                ><span v-html="moreText"> </span>
                <img
                    class="article-banner-more-link-icon"
                    src="@/assets/images/Icons/icon_linkpfeil-blau_rechts.svg"
            /></a>
        </div>
    </div>
</template>
<script>
/* types are:
 *
 */

import ArticleSlide from '../../../../pages/article/elements/ArticleSlide';
export default {
    name: 'ArticleBanner',
    components: { ArticleSlide },
    props: {
        label: String,
        subLabel: String,
        moreText: String,
        articles: Array,
    },
};
</script>

<style scoped>
@media screen and (min-width: 320px) and (max-width: 470px) {
    .product-slide {
        width: 248px !important;
    }
}

@media screen and (min-width: 470px) and (max-width: 640px) {
    .product-slide {
        width: 392px !important;
    }
}

.article-banner-more {
    text-align: right;
}

.article-banner-more-link {
    text-align: right;
    color: #27348b;
}

.article-banner-more-link-icon {
    height: 16px;
    display: inline-block;
}

.product-slide >>> .v-icon {
    font-size: 60px;
    color: #161616;
}

.product-slide >>> .v-icon.v-icon--disabled {
    color: #0000002b !important;
}

.v-slide-group__wrapper {
    padding-top: 5px;
}
</style>
