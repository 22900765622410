import ArticleOne from '../../assets/images/articleColors/Farbwahl_1.jpg';
import ArticleTwo from '../../assets/images/articleColors/Farbwahl_2.jpg';
import ArticleThree from '../../assets/images/articleColors/Farbwahl_3.jpg';
import ArticleFour from '../../assets/images/articleColors/Farbwahl_4.jpg';
import ArticleFive from '../../assets/images/articleColors/Farbwahl_5.jpg';
import ArticleSix from '../../assets/images/articleColors/Farbwahl_6.jpg';
import ArticleSeven from '../../assets/images/articleColors/Farbwahl_7.jpg';

import ArticleImage from '../../assets/images/singleArticle/Produktbild_groß.jpg';

const articleVarieties = [
    {
        picture: ArticleOne,
    },
    {
        picture: ArticleTwo,
    },
    {
        picture: ArticleThree,
    },
    {
        picture: ArticleFour,
    },

    {
        picture: ArticleFive,
    },
    {
        picture: ArticleSix,
    },
    {
        picture: ArticleSeven,
    },
];

const articleImages = [
    {
        picture: ArticleImage,
    },
];

export { articleVarieties, articleImages };
