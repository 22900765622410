<template>
    <div>
        <h3 class="tw-text-2xl tw-text-center tw-mb-8">
            Der Titan Xenon Trolley - new look, classic style
        </h3>
        <img
            class="tw-mb-8 tw-w-full"
            src="@/assets/images/singleArticle/Beispiel-Contenbild_4spaltig.jpg"
        />
        <p class="tw-font-light tw-mb-8">
            Es gibt im Moment in diese Mannschaft, oh, einige Spieler vergessen
            ihnen Profi was sie sind. Ich lese nicht sehr viele Zeitungen, aber
            ich habe gehört viele Situationen. Erstens: wir haben nicht offensiv
            gespielt. Es gibt keine deutsche Mannschaft spielt offensiv und die
            Name offensiv wie Bayern. Letzte Spiel hatten wir in Platz drei
            Spitzen: Elber, Jancka und dann Zickler. Wir müssen nicht vergessen
            Zickler. Zickler ist eine Spitzen mehr, Mehmet eh mehr Basler. Ist
            klar diese Wörter, ist möglich verstehen, was ich hab gesagt? Danke.
            Offensiv, offensiv ist wie machen wir in Platz. Zweitens: ich habe
            erklärt mit diese zwei Spieler: nach Dortmund brauchen vielleicht
            Halbzeit Pause. Ich habe auch andere Mannschaften gesehen in Europa
            nach diese Mittwoch. Ich habe gesehen auch zwei Tage die Training.
            Ein Trainer ist nicht ein Idiot! Ein Trainer sei sehen was passieren
            in Platz. In diese Spiel es waren zwei, drei diese Spieler waren
            schwach wie eine Flasche leer! Haben Sie gesehen Mittwoch, welche
            Mannschaft hat gespielt Mittwoch? Hat gespielt Mehmet oder gespielt
            Basler oder hat gespielt Trapattoni? Diese Spieler beklagen mehr als
            sie spielen! Wissen Sie, warum die Italienmannschaften kaufen nicht
            diese Spieler? Weil wir haben gesehen viele Male solche Spiel!
        </p>
        <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-mb-12">
            <div class="xs:tw-col-span-12">
                <h3 class="tw-text-2xl tw-mb-5">Features</h3>
                <ol class="tw-font-light list-numbers">
                    <li>Fünf Größen</li>
                    <li>Noch ein Punkt</li>
                    <li>Der nächste Punkt</li>
                    <li>Das auch noch</li>
                    <li>Insgesamt einfach super</li>
                </ol>
                <p class="tw-font-light tw-mb-4">
                    Es gibt im Moment in diese Mannschaft, oh, einige Spieler
                    vergessen ihnen Profi was sie sind. Ich lese nicht sehr
                    viele Zeitungen, aber ich habe gehört viele Situationen.
                    Erstens: wir haben nicht offensiv gespielt. Es gibt keine
                    deutsche Mannschaft spielt offensiv und die Name offensiv
                    wie Bayern.
                </p>
                <ul class="tw-font-light list-dots">
                    <li>Fünf Größen</li>
                    <li>Noch ein Punkt</li>
                    <li>Der nächste Punkt</li>
                    <li>Das auch noch</li>
                    <li>Insgesamt einfach super</li>
                </ul>
            </div>
            <div class="xs:tw-col-span-12">
                <img
                    class="tw-mb-2"
                    src="@/assets/images/singleArticle/Beispiel-Vorschaubild-Video_1.jpg"
                />
                <img
                    src="@/assets/images/singleArticle/Beispiel-Vorschaubild-Video_2.jpg"
                />
            </div>
        </div>
        <Seperator />
    </div>
</template>

<script>
import Seperator from '@/components/content/items/Seperator';
export default {
    name: 'ArticleDescription',
    components: { Seperator },
    data: () => ({}),
};
</script>

<style scoped>
.list-numbers {
    list-style: decimal;
    margin-left: 18px;
    padding-left: 0;
}
.list-dots {
    list-style: square;
    margin-left: 18px;
    padding-left: 0;
}
.list-numbers li,
.list-dots li {
    margin-bottom: 8px;
}
</style>