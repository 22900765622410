<template>
    <div class="tw-mx-auto">
        <div class="tw-relative tw-flex" v-if="!carousel">
            <img
                src="@/assets/images/Icons/icon-pfeil-slider_links_schwarz.svg"
                class="arrow-previous carousel tw-hidden md:tw-block"
            />
            <div class="carousel-container tw-flex">
                <div
                    v-for="(article, key) in articleVarieties"
                    :key="key"
                    class="carousel-item"
                >
                    <img
                        style="border: 1px solid grey"
                        :src="article.picture"
                    />
                </div>
            </div>
            <img
                src="@/assets/images/Icons/icon-pfeil-slider_rechts_schwarz.svg"
                class="arrow-next carousel tw-hidden md:tw-block"
            />
        </div>
        <div v-if="carousel" class="tw-relative">
            <img
                src="@/assets/images/Icons/icon-pfeil-slider_links_schwarz.svg"
                class="arrow-previous"
            />
            <div v-for="(article, key) in articleImages" :key="key">
                <img :src="article.picture" />
            </div>
            <img
                src="@/assets/images/Icons/icon-pfeil-slider_rechts_schwarz.svg"
                class="arrow-next"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticleVariationSlide',
    props: {
        width: [String, Number],
        articleVarieties: Array,
        articleImages: Array,
        carousel: Boolean,
        showArrow: Boolean,
    },
};
</script>

<style scoped>
.arrow-previous {
    position: absolute;
    z-index: 3;
    width: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    cursor: pointer;
}
.arrow-next {
    position: absolute;
    z-index: 3;
    width: 20px;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
    cursor: pointer;
}
.arrow-next.carousel {
    width: 12px;
    right: -22px;
}
.arrow-previous.carousel {
    left: -22px;
    width: 12px;
}
.carousel-item {
    margin-right: 5px;
}
.carousel-container .carousel-item:last-child {
    margin-right: 0px !important;
}
</style>
