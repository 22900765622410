<template>
  <div class="main-page-size tw-pt-5">
    <div class="tw-relative">
            <BreadCrumbs :crumbs="this.breadCumbs"/>
            <ArticlePresentation/>
            <ArticleDetails/>
            <ArticleDescription/>

            <ArticleBanner
                label="Weitere Produkte der Marke"
                subLabel="Mustertext"
                moreText="Mehr Produkte <span class='tw-underline' >dieser Marke</span> entdecken"
                :articles="similarArticles"/>

            <ArticleBanner
                label="Ähnliche Produkte"
                subLabel="Empfehlungen für Sie"
                moreText="Mehr aus der Kategorie <span class='tw-underline' >Handgepäck Trolleys</span> entdecken"
                :articles="similarArticles"/>


        </div>
    </div>
</template>

<script>
import BreadCrumbs from '@/components/content/layout/BreadCrumbs';
import ArticleBanner from '@/components/content/items/article/ArticleBanner';
import ArticleDescription from '@/pages/article/elements/ArticleDescription';
import ArticleDetails from '@/pages/article/elements/ArticleDetails';
import ArticlePresentation from '@/pages/article/elements/ArticlePresentation';

export default {
    name: 'Article',
    components: {
        ArticlePresentation,
        ArticleDetails,
        ArticleDescription,
        ArticleBanner,
        BreadCrumbs,
    },
    data: () => ({
        breadCumbs: [
            {
                label: 'Reisegepäck',
                link: '/kategorie',
            },
            {
                label: 'Handgepäck',
                link: '/kategorie',
            },
            {
                label: 'Handgepäck Trolleys',
                link: '/kategorie',
            },
        ],
        similarArticles: [
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '1',
                price: '628,00',
                oldPrice: '628,00',
                colors: ['grey', 'blue'],
                isNew: true,
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '2',
                price: '628,00',
                colors: ['black', 'blue'],
                isNew: false,
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '3',
                price: '628,00',
                colors: ['red', 'blue'],
                isNew: false,
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '4',
                price: '628,00',
                oldPrice: '628,00',
                isNew: false,
            },
        ],
    }),
};
</script>
