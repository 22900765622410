<template>
    <div >
        <div class="tw-grid tw-grid-cols-12 tw-gap-2 tw-mb-12">
            <div
                class="lg:tw-col-span-7 md:tw-col-span-6 sm:tw-col-span-12 tw-col-span-12"
            >
                <h1
                    class="xs:tw-text-xl xs:tw-pb-5 tw-text-2xl tw-flex sm:tw-hidden"
                >
                    Titan Xenon Trolley L 4 Rollen Headline kann zweizeilig
                    werden
                </h1>
                <ArticleVariationSlide
                    carousel
                    class="tw-flex sm:tw-hidden"
                    :articleImages="articleImages"
                />
                <div
                    class="tw-grid tw-grid-cols-5 tw-gap-2 tw-mb-12 tw-hidden sm:tw-flex"
                >
                    <div class="details-image-container tw-col-span-1">
                        <img
                            v-for="(image, key) in imageList"
                            :key="key"
                            :src="
                                require(`@/assets/images/singleArticle/Vorschaubild_${image.src}.jpg`)
                            "
                            class="details-image-node tw-mb-1"
                        />
                    </div>
                    <div class="tw-col-span-4 tw-relative">
                        <img
                            src="@/assets/images/Icons/icon-pfeil-slider_links_schwarz.svg"
                            class="arrow-previous"
                        />
                        <img
                            src="@/assets/images/singleArticle/Produktbild_groß.jpg"
                        />
                        <img
                            src="@/assets/images/Icons/icon-pfeil-slider_rechts_schwarz.svg"
                            class="arrow-next"
                        />
                    </div>
                </div>
            </div>
            <div
                class="lg:tw-col-span-5 md:tw-col-span-6 sm:tw-col-span-12 tw-col-span-12"
            >
                <h1
                    class="xs:tw-text-2xl tw-hidden sm:tw-flex sm:tw-text-lg md:tw-text-2xl lg:tw-text-3xl"
                >
                    Titan Xenon Trolley L 4 Rollen Headline kann zweizeilig
                    werden
                </h1>
                <div class="tw-font-light tw-pt-5">
                    UVP
                    <span class="tw-line-through">EUR 199,00</span>
                </div>
                <div class="tw-pb-5 tw-pt-2">
                    <span class="tw-text-4xl tw-pr-2">EUR 159,00</span
                    ><span class="tw-text-sm tw-font-light"
                        >inkl. 19% MwSt.</span
                    >
                </div>
                <p class="text-orange">
                    Sie sparen EUR 40,00
                    <span class="orange-badge">-10 %</span>
                </p>
                <Seperator />
                <div>
                    <h3 class="tw-text-l">Größe:</h3>
                    <div class="tw-flex tw-flex-col lg:tw-flex-row">
                        <div class="sizes">
                            <div>S</div>
                            <div class="isActive">M</div>
                            <div>L</div>
                            <div>XL</div>
                        </div>
                        <div class="product-size-container">
                            <div class="tw-text-l product-size-title">
                                Größe M:
                            </div>
                            <div class="product-size">
                                Außenmaße (HxBxT): 74x53x31 cm
                            </div>
                            <div class="product-size">
                                Besonders geeignet für 1-wöche Reisen
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="tw-text-2xl tw-py-3">Farbe:</h3>
                <v-select
                    block
                    v-model="color"
                    :items="colors"
                    outlined
                ></v-select>
                <ArticleVariationSlide :articleVarieties="articleVarieties" />
                <div class="tw-pt-2 tw-pb-5 sm:tw-text-sm md:tw-text-lg">
                    <a>
                        Persönliches Monogramm hinzufügen
                        <img
                            class="end link"
                            src="@/assets/images/Icons/icon_linkpfeil-blau_rechts.svg"
                    /></a>
                </div>
                <v-btn
                    depressed
                    block
                    color="primary"
                    height="55"
                    tile
                    @click="openBasketDrawerEvent()"
                >
                    In den Warenkorb
                    <v-icon right dark>
                        mdi-cart-minus
                    </v-icon>
                </v-btn>
                <div class="tw-py-5">
                    <div class="tw-font-light">
                        <span class="tw-font-normal tw-text-green-700">
                            <v-icon color="green darken-3">
                                mdi-check
                            </v-icon>
                            Sofort lieferbar,</span
                        >
                        aber nur noch wenig Artikel verfügbar!
                    </div>
                    <div class="tw-font-light tw-text-xs">
                        Voraussichtliche Lieferung:
                        <span class="tw-font-medium">Montag</span>, wenn Sie in
                        den nächsten 11 Std. bestellen
                    </div>
                </div>
                <div class="tw-font-light tw-pb-5">
                    <span class="tw-font-normal tw-text-green-700">
                        <v-icon color="green darken-3">
                            mdi-check
                        </v-icon>
                        Vorrätig in unseren Fillialen</span
                    >
                    <a class="tw-font-medium tw-pl-2">
                        am
                        <a href="#" class="tw-underline">
                            Frankfurter Flughafen
                        </a>
                        <img
                            class="end link"
                            src="@/assets/images/Icons/icon_linkpfeil-blau_rechts.svg"
                    /></a>
                </div>
            </div>
        </div>
        <Seperator />
    </div>
</template>

<script>
import Seperator from '@/components/content/items/Seperator';
import ArticleVariationSlide from '@/components/content/items/article/ArticleVariationSlide';
import {
    articleVarieties,
    articleImages,
} from '@/services/data-mock/articleVarieties';
import { EventBus } from '@/event-bus';

export default {
    name: 'ArticlePresentation',
    components: { Seperator, ArticleVariationSlide },
    data: () => ({
        drawer: null,
        color: 'Blue',
        articleVarieties: articleVarieties,
        articleImages: articleImages,
        colors: ['Blue', 'Brown', 'Black', 'Grey'],
        imageList: [
            {
                src: '1',
            },
            {
                src: '2',
            },
            {
                src: '3',
            },
            {
                src: '4',
            },
            {
                src: '5',
            },
        ],
    }),
    methods: {
        toggleTab() {
            this.firstTabActive = !this.firstTabActive;
        },
        openBasketDrawerEvent() {
            EventBus.$emit('open-basket-drawer');
        },
    },
    articleVarieties,
};
</script>

<style scoped>
.text-green {
    color: #17853c;
}
.details-image-container {
    max-height: 550px;
    overflow: hidden;
}
.details-image-node {
    border: 1px solid #cccccc;
}
.details-image-node:first-child {
    border: 1px solid black;
}
img.link {
    width: 20px;
    margin-top: -2px;
    margin-right: 8px;
    display: inline-block;
}
img.link.end {
    margin-left: 4px;
}
.text-orange {
    color: #ec6608;
}
.orange-badge {
    background-color: #ec6608;
    color: white;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
}
.sizes div {
    height: 50px;
    width: 50px;
    padding-top: 12px;
    text-align: center;
    border: 1px solid #cccccc;
    margin-right: 4px;
    display: inline-block;
}
.sizes div.isActive {
    border: 1px solid black;
}
.v-input >>> .v-input__slot {
    border-radius: 0;
}
.v-input >>> fieldset {
    border-color: #9e9e9e;
    color: #858585;
}

@media (max-width: 1023px) {
    .product-size-container {
        padding-top: 15px;
    }
}

@media (min-width: 1024px) {
    .product-size-container {
        padding-left: 15px;
    }
}

.product-size-title {
    color: #7c7c7c;
    font-size: 13px;
}
.product-size {
    color: #484848;
    font-size: 11px;
    font-weight: 300;
}

.arrow-previous {
    position: absolute;
    z-index: 3;
    width: 16px;
    top: 0;
    bottom: 0;
    left: 30px;
    margin: auto 0;
    cursor: pointer;
}
.arrow-next {
    position: absolute;
    z-index: 3;
    width: 16px;
    top: 0;
    bottom: 0;
    right: 30px;
    margin: auto 0;
    cursor: pointer;
}
</style>
